<template>
  <div class="demo">
    <MyNavBar title="demo" :leftArrow="false"/>
      <van-search clearable v-model="value" placeholder="请输入搜索关键词" />
    <div class="container">
      <div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item>1</van-swipe-item>
          <van-swipe-item>2</van-swipe-item>
          <van-swipe-item>3</van-swipe-item>
          <van-swipe-item>4</van-swipe-item>
        </van-swipe>
      </div>
      <van-cell title="AddressList 地址列表" to="/addressList" icon="location-o" is-link />

      <hr>
      <van-button type="default" @click="onClick()">默认按钮</van-button>
      <hr>
      <van-button type="primary">主要按钮</van-button>
      <hr>
      <van-button type="info">信息按钮</van-button>
      <hr>
      <van-button type="warning">警告按钮</van-button>
      <hr>
      <van-button type="danger">危险按钮</van-button>
      <hr>

    </div>

    <MyTabBar active="demo"/>


  </div>
</template>
<script>
  import { Notify } from 'vant';
  import { Toast } from 'vant';
  import MyNavBar from '@/components/MyNavBar.vue';
  import MyTabBar from '@/components/MyTabBar.vue';

  export default {
    name: 'Demo',
    components: {
      MyNavBar,
      MyTabBar
    },
    data() {
      return {
        timer: "zxc",
          value: "",
        active: 0
      };
    },
    methods: {
      onClickLeft(index) {

        Toast('返回');
      },
      onClick(index) {

        Notify('通知内容');
        // Notify({ type: 'primary', message: index });
      },
    },
  }
</script>
<style lang="less">
  .van-nav-bar .van-icon {
    color: #323233;
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
</style>
