<template>
  <div class="form">
    <MyNavBar :title="title" :leftArrow="false"/>

    <div class="container">

      <div class="demo-item">
          <div class="demo-block" style="padding: 10px 10px 0">
              <van-search
                  v-model="value"
                  show-action
                  placeholder="请输入搜索关键词"
                  @search="onSearch"
              >
                  <template #action>
                      <div @click="onSearch">搜索</div>
                  </template>
              </van-search>
          </div>
      </div>
      <div class="demo-item">
<!--        <p class="demo-desc">Form</p>-->
        <div class="demo-block" style="padding: 10px">
            <van-cell is-link center v-for="(item,index) in 10" :key="index" @click="goClick(item)">
                <template #title>
                    <span class="custom-title">报告编号:AP--2024-03{{ item }}</span>
                </template>
                <template #label>
                    <span class="custom-title">报告时间:2020-02-20</span>
                </template>
            </van-cell>
            <van-empty image="search" description="暂无项目~" />
        </div>
      </div>
    </div>


  </div>
</template>
<script>
  import { Notify } from 'vant';
  import { Toast } from 'vant';
  import MyNavBar from '@/components/MyNavBar.vue';


  export default {
    name: 'Form',
    components: {MyNavBar},
    data() {
      return {
          title: '查询项目',
          form: {
              time:'2020-02-20',
              type:'安全评估',
              no:'AP--2024-041',
              username:'张三',
          },
          value:'',
      };
    },
    methods: {
        onSearch(index) {
        Toast('返回');
      },
        goClick(row) {
            this.$router.push({ name: 'info', params: { flowId: row } })
            // this.$router.push('/index');
      },
      onClickLeft(index) {
        Toast('返回');
      },
      onClick(index) {

        Notify('通知内容');
        // Notify({ type: 'primary', message: index });
      },
    },
  }
</script>
<style lang="less">

</style>
