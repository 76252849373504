<template>
  <div class="form">
    <MyNavBar title="表单" :leftArrow="true"/>

    <div class="container">

      <div class="demo-item">
        <p class="demo-desc">Form</p>
        <div class="demo-block">

          <van-form @submit="onSubmit">
            <van-field
              v-model="username"
              name="用户名"
              label="用户名"
              placeholder="用户名"
            />
            <van-field
              v-model="password"
              type="password"
              name="密码"
              label="密码"
              placeholder="密码"
            />

            <van-field name="switch" label="开关">
              <template #input>
                <van-switch v-model="switchChecked" size="20" />
              </template>
            </van-field>

            <van-field name="checkbox" label="复选框">
              <template #input>
                <van-checkbox v-model="checkbox" shape="square" />
              </template>
            </van-field>

            <van-field name="checkboxGroup" label="复选框组">
              <template #input>
                <van-checkbox-group v-model="checkboxGroup" direction="horizontal">
                  <van-checkbox name="1" shape="square">复选框 1</van-checkbox>
                  <van-checkbox name="2" shape="square">复选框 2</van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>

            <van-field name="radio" label="单选框">
              <template #input>
                <van-radio-group v-model="radio" direction="horizontal">
                  <van-radio name="1">单选框 1</van-radio>
                  <van-radio name="2">单选框 2</van-radio>
                </van-radio-group>
              </template>
            </van-field>

            <van-field name="stepper" label="步进器">
              <template #input>
                <van-stepper v-model="stepper" />
              </template>
            </van-field>

            <van-field name="rate" label="评分">
              <template #input>
                <van-rate v-model="rate" />
              </template>
            </van-field>

            <van-field name="slider" label="滑块">
              <template #input>
                <van-slider v-model="slider" />
              </template>
            </van-field>

            <van-field name="uploader" label="文件上传">
              <template #input>
                <van-uploader v-model="uploader" />
              </template>
            </van-field>

            <van-field
              readonly
              clickable
              name="picker"
              :value="value"
              label="选择器"
              placeholder="点击选择"
              @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
              <van-picker
                show-toolbar
                :columns="columns"
                @confirm="onConfirm"
                :default-index="0"
                @cancel="showPicker = false"
              />
            </van-popup>

            <van-field
              readonly
              clickable
              name="area"
              :value="valueArea"
              label="地区选择"
              placeholder="点击选择省市区"
              @click="showArea = true"
            />
            <van-popup v-model="showArea" position="bottom">
              <van-area
                name="area2"
                :area-list="areaList"
                @confirm="onConfirmArea"
                :value="valueArea2"
                @cancel="showArea = false"
              />
            </van-popup>

            <van-field-select-picker
              label="单选select"
              placeholder="请选择"
              v-model="value2"
              :columns="columns2"
              :option="{label:'name',value:'code'}"
              @confirm="confirm2"
            />

            <van-field-checkbox
              label="多选select"
              placeholder="请选择"
              v-model="value3"
              :columns="columns2"
              :option="{label:'name',value:'code'}"
              @confirm="confirm3"
            />
            <div style="margin: 16px;">
              <van-button round block type="info" native-type="submit">提交</van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>

    <MyTabBar active="form"/>


  </div>
</template>
<script>
  import { Notify } from 'vant';
  import { Toast } from 'vant';
  import MyNavBar from '@/components/MyNavBar.vue';
  import MyTabBar from '@/components/MyTabBar.vue';
  import VanFieldSelectPicker from '@/components/select/VanFieldSelectPicker.vue';
  import VanFieldCheckbox from '@/components/select/VanFieldCheckbox.vue';

  import  areaList  from '@/assets/js/area.js';

  export default {
    name: 'Form',
    components: {MyNavBar, MyTabBar, VanFieldSelectPicker, VanFieldCheckbox},
    data() {
      return {
        username: '',
        password: '',
        switchChecked: false,
        checkbox: false,
        checkboxGroup: [],
        radio: '1',
        stepper: 1,
        rate: 3,
        slider: 50,
        uploader: [{ url: 'https://img01.yzcdn.cn/vant/leaf.jpg' }],
        value: '',
        valueArea: '',
        valueArea2: '320506',
        modelArea: '',
        columns: [
          {
            text:"选项一",
            siteState:'1',
          },{
            text:"选项二",
            siteState:'2',
          }
        ],
        showPicker: false,
        showArea: false,
        areaList: areaList,
        value2: '4', // select选中的value
        value3: ['1','8'], // select选中的value
        columns2: [// 如果可选数据不是label-value,需要配置下option，如果是就不需要配置
          { name: '1我是选中的label', code: '1' },
          { name: '2我是选中的label', code: '2' },
          { name: '3我是选中的label', code: '3' },
          { name: '4我是选中的label', code: '4' },
          { name: '5我是选中的label', code: '5' },
          { name: '6我是选中的label', code: '6' },
          { name: '7我是选中的label', code: '7' },
          { name: '8我是选中的label', code: '8' }
        ]
      };
    },
    methods: {
      confirm2 (data1, index, data2) { // checkbox确定,
        // tips 正常获取值，用不到这个方法，用v-model获取值即可，这个方法是告诉你，可以获取任何你想要的数据
        // data1 当前这一条的obj数据
        // index 当前选择的索引
        // data2 当前这一条数据的value
        console.log(data1, data2, index)
        this.value4 = data1
      },
      confirm3 (data1, data2) { // select确定,
        // tips 正常获取值，用不到这个方法，用v-model获取值即可，这个方法是告诉你，可以获取任何你想要的数据
        // data1 是当前选中数据的value的数组
        // data2 是当前选中数据的整个obj集合
        console.log(data1, data2)
        this.value5 = data2
      },
      onSubmit(values) {
        console.log('submit', values);
        console.log('value2', this.value2);
      },
      onConfirmArea(values) {
        debugger
        this.valueArea2 = values
          .filter((item) => !!item)
          .map((item) => item.code)
          .join('/');
        this.valueArea = values
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
        this.showArea = false;
      },
      onConfirmField(values) {
        this.value = values
          .filter((item) => !!item)
          .map((item) => item.name)
          .join('/');
        this.showArea = false;
      },
      onConfirm(value) {
        this.value = value.text;
        this.showPicker = false;
      },

      onClickLeft(index) {
        Toast('返回');
      },
      onClick(index) {

        Notify('通知内容');
        // Notify({ type: 'primary', message: index });
      },
    },
  }
</script>
<style lang="less">

</style>
