<template>
  <div class="demo">
    <MyNavBar title="AddressList 地址列表" :leftArrow="true"/>

    <div class="container">

      <div class="demo-item">
        <div class="demo-block">
          <van-address-list
            v-model="chosenAddressId"
            :list="list"
            :disabled-list="disabledList"
            disabled-text="以下地址超出配送范围"
            default-tag-text="默认"
            @add="onAdd"
            @edit="onEdit"
          />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import { Notify } from 'vant';
  import { Toast } from 'vant';
  import MyNavBar from '@/components/MyNavBar.vue';

  export default {
    name: 'Demo',
    components: {MyNavBar},
    data() {
      return {
        chosenAddressId: '1',
        list: [
          {
            id: '1',
            name: '张三',
            tel: '13000000000',
            address: '浙江省杭州市西湖区文三路 138 号东方通信大厦 7 楼 501 室',
            isDefault: true,
          },
          {
            id: '2',
            name: '李四',
            tel: '1310000000',
            address: '浙江省杭州市拱墅区莫干山路 50 号',
          },
        ],
        disabledList: [
          {
            id: '3',
            name: '王五',
            tel: '1320000000',
            address: '浙江省杭州市滨江区江南大道 15 号',
          },
        ],
      };
    },
    methods: {
      onAdd() {
        Toast('新增地址');
        this.$router.push({ path: '/addressEdit' });
      },
      onEdit(item, index) {
        debugger
        Toast('编辑地址:' + index);
      },
    },
  }
</script>
<style lang="less">
  .van-nav-bar .van-icon {
    color: #323233;
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
</style>
