<template>
  <div class="demo">
    <MyNavBar title="AddressEdit 地址编辑" :leftArrow="true"/>

    <div class="container">

      <div class="demo-item">
        <div class="demo-block">
          <van-address-edit
            :area-list="areaList"
            show-postal
            show-delete
            show-set-default
            show-search-result
            :search-result="searchResult"
            :area-columns-placeholder="['请选择', '请选择', '请选择']"
            @save="onSave"
            @delete="onDelete"
            @change-detail="onChangeDetail"
          />
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import { Notify } from 'vant';
  import { Toast } from 'vant';
  import MyNavBar from '@/components/MyNavBar.vue';

  import  areaList  from '@/assets/js/area.js';

  export default {
    name: 'Demo',
    components: {MyNavBar},
    data() {
      return {
        areaList,
        searchResult: [],
      };
    },
    methods: {
      onSave(content) {
        debugger;
        var a = this.searchResult;
        Toast('save');
      },
      onDelete() {
        Toast('delete');
      },
      onChangeDetail(val) {
        if (val) {
          this.searchResult = [
            {
              name: '黄龙万科中心',
              address: '杭州市西湖区',
            },
          ];
        } else {
          this.searchResult = [];
        }
      },
    },
  }
</script>
<style lang="less">
  .van-nav-bar .van-icon {
    color: #323233;
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
</style>
