<template>
  <div class="form">
    <MyNavBar :title="title" :leftArrow="false"/>

    <div class="container">
      <div class="demo-item">
        <div class="demo-block" style="padding: 10px">
            <div class="index-logo-box" v-if="logoShow">
                <van-image
                    width="10rem"
                    height="10rem"
                    fit="contain"
                    :src="logoImg"
                />
                <div>
                    中智报告查询系统
                </div>
            </div>
            <div>
                <van-search
                    v-model="value"
                    show-action
                    clearable
                    placeholder="请输入搜索关键词"
                    @search="onSearch"
                >
                    <template #action>
                        <div @click="onSearch">搜索</div>
                    </template>
                </van-search>
            </div>
        </div>
      </div>


        <div class="demo-item" v-if="!logoShow">
            <!--        <p class="demo-desc">Form</p>-->
            <div class="demo-block" style="padding: 10px">
                <div v-if="listShow">
                    <van-cell is-link center v-for="(item,index) in projectList" :key="index" @click="goClick(item)">
                        <template #title>
                            <span class="custom-title">报告编号:{{ item.no }}</span>
                        </template>
                        <template #label>
                            <!--                        <span class="custom-title">报告时间:2020-02-20</span>-->
                        </template>
                    </van-cell>
                </div>
                <van-empty v-if="!listShow" image="search" description="暂无项目~" />
            </div>
        </div>
    </div>


  </div>
</template>
<script>
  import { Notify } from 'vant';
  import { Toast } from 'vant';
  import MyNavBar from '@/components/MyNavBar.vue';
  import logoImg from '@/assets/img/logo.png';


  export default {
    name: 'Form',
    components: {MyNavBar},
    data() {
      return {
          title: '报告查询',
          form: {
              time:'2020-02-20',
              type:'安全评估',
              no:'AP--2024-041',
              username:'张三',
          },
          value:'',
          logoImg:logoImg,
          logoShow:true,
          listShow:true,
          url:{
              projectList: "/biz/public/project/list",
              projectInfo: "/biz/public/project/",
          },
          projectList: [],
      };
    },
    methods: {
        goClick(row) {
            this.$router.push({ path: '/info/'+row.id  })
            // this.$router.push({ name: 'info', params: { projectId: row.id } })
        },
        onCancel(row) {
            this.value= ''
        },
        onSearch(row) {
            if (this.value) {
                this.logoShow = false
                let query = {
                    no: this.value
                }
                this.get(query, "/biz/public/project/list").then((res) => {
                    console.log(res)
                    if (res.code === 200) {
                        // Notify({ type: 'success', message: res.msg });
                        this.projectList = res.rows
                        if (res.rows.length !=0){
                            this.listShow = true
                            this.projectList = res.rows
                        } else {
                            this.listShow = false
                        }
                    } else {
                        Notify(res.msg);
                    }
                });
            } else {

                Notify('搜索框不能为空~');
            }
      },
      onClickLeft(index) {
        Toast('返回');
      },
      onClick(index) {

        Notify('通知内容');
        // Notify({ type: 'primary', message: index });
      },
    },
  }
</script>
<style lang="less">
.container {
    .index-logo-box {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
    }
}
</style>
